.query-list {
    padding: 30px 0;
    font-size: 14px;
    background-color: #fff;

    .query-row {
        margin-bottom: 10px;
        border-bottom: 1px solid @gray-lighter;
    }
    .query-row:last-child {
        margin-bottom: 0;
    }
    .query-row {
        margin-bottom: 20px;
        border-bottom: 1px solid @gray-lighter;
    }
    .query-title {
        float: left;
        width: 10%;
    }
    .query-content {
        float: left;
        width: 90%;
    }
    .query-content ul{
        float: left;
        margin-bottom: 10px;
    }
    .query-content ul.list-inline>li {
        padding: 0 15px;
        margin-bottom: 10px;
    }
    .query-content ul.list-inline>li>a {
        color: @gray-light;
    }
    .query-content ul.list-inline>li.active>a {
        color: @brand-primary;
    }
    @media (max-width: @screen-sm) {
        .query-title {
            width: 15%;
        }
        .query-content {
            width: 85%;
        }
    }
}
@media (max-width: @screen-sm) {
    .query-list {
        padding: 0px;
    }
}
