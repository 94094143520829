.nav-justified-xs>li {
    display: table-cell;
    width: 1%
}
.nav-close .close {
    float: none;
    font-size: 18px;
    line-height: 16px;
    margin-left: 2px;
}
