/* 联系人样式 */
.contact-box {
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 20px;

    .contact-avatar {
        padding: 0;

        .info {
            margin-top: 10px;
            font-size: 12px;
        }
    }

    a {
        color: inherit;
    }
    a:hover {
        text-decoration: none;
    }
    .contact-title h3 {
        color: @brand-primary;
        font-size: 14px;
        margin-top: 10px;
    }
    .contact-content {
        color: @gray;
        font-size: 12px;
        margin-bottom: 20px;
    }
}
