// iframe样式
.iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

// 背景视差滚动
.stretch {
    background-position: center center;
    background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

// 动画缩放
@keyframes indexZoom {
    0% {
        transform: scale(1,1);
        -ms-transform:scale(1,1);
        -moz-transform:scale(1,1);
        -webkit-transform:scale(1,1);
        -o-transform:scale(1,1);
    }
    100% {
        transform: scale(1.3,1.3);
        -ms-transform:scale(1.3,1.3);
        -moz-transform:scale(1.3,1.3);
        -webkit-transform:scale(1.3,1.3);
        -o-transform:scale(1.3,1.3);
    }
}
@-webkit-keyframes indexZoom {
    0%   {
        transform: scale(1,1);
        -ms-transform:scale(1,1);
        -moz-transform:scale(1,1);
        -webkit-transform:scale(1,1);
        -o-transform:scale(1,1);
    }
    100% {
        transform: scale(1.3,1.3);
        -ms-transform:scale(1.3,1.3);
        -moz-transform:scale(1.3,1.3);
        -webkit-transform:scale(1.3,1.3);
        -o-transform:scale(1.3,1.3);
    }
}
@-moz-keyframes indexZoom {
    0%   {
        transform: scale(1,1);
        -ms-transform:scale(1,1);
        -moz-transform:scale(1,1);
        -webkit-transform:scale(1,1);
        -o-transform:scale(1,1);
    }
    100% {
        transform: scale(1.3,1.3);
        -ms-transform:scale(1.3,1.3);
        -moz-transform:scale(1.3,1.3);
        -webkit-transform:scale(1.3,1.3);
        -o-transform:scale(1.3,1.3);
    }
}
@-o-keyframes indexZoom {
    0%   {
        transform: scale(1,1);
        -ms-transform:scale(1,1);
        -moz-transform:scale(1,1);
        -webkit-transform:scale(1,1);
        -o-transform:scale(1,1);
    }
    100% {
        transform: scale(1.3,1.3);
        -ms-transform:scale(1.3,1.3);
        -moz-transform:scale(1.3,1.3);
        -webkit-transform:scale(1.3,1.3);
        -o-transform:scale(1.3,1.3);
    }
}
