.badge-primary {
    background: @brand-primary;
}
.badge-success {
    background: @brand-success;
}
.badge-info {
    background: @brand-info;
}
.badge-warning {
    background: @brand-warning;
}
.badge-danger {
    background: @brand-danger;
}
