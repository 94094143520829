// Label Outline
// --------------------------------------------------

.label-default-outline {
  .button-outline-variant(@label-default-bg);
}
.label-primary-outline {
  .button-outline-variant(@label-primary-bg);
}
.label-success-outline {
  .button-outline-variant(@label-success-bg);
}
.label-info-outline {
  .button-outline-variant(@label-info-bg);
}
.label-warning-outline {
  .button-outline-variant(@label-warning-bg);
}
.label-danger-outline {
  .button-outline-variant(@label-danger-bg);
}


//
// Custom button radius sizes
//

.label-pill {
  padding-left: 0.75em;
  padding-right: 0.75em;
  border-radius: 1000em;
}

.label-square {
  border-radius: 0;
}
