@font-face {
  font-family: 'lyicon';
  src:  url('../fonts/lyicon.eot?355gqt');
  src:  url('../fonts/lyicon.eot?355gqt#iefix') format('embedded-opentype'),
    url('../fonts/lyicon.ttf?355gqt') format('truetype'),
    url('../fonts/lyicon.woff?355gqt') format('woff'),
    url('../fonts/lyicon.svg?355gqt#lyicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571429em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714286em;
}
.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left {
  margin-right: .3em;
}
.fa.fa-pull-right {
  margin-left: .3em;
}
/* Deprecated as of 4.4.0 */
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.fa.pull-left {
  margin-right: .3em;
}
.fa.pull-right {
  margin-left: .3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #ffffff;
}

[class^="fa-"], [class*=" fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lyicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-file-accdb:before {
  content: "\e900";
}
.fa-file-avi:before {
  content: "\e901";
}
.fa-file-bmp:before {
  content: "\e902";
}
.fa-file-css:before {
  content: "\e903";
}
.fa-file-doc:before {
  content: "\e904";
}
.fa-file-docx:before {
  content: "\e905";
}
.fa-file-eml:before {
  content: "\e906";
}
.fa-file-eps:before {
  content: "\e907";
}
.fa-file-fla:before {
  content: "\e908";
}
.fa-file-gif:before {
  content: "\e909";
}
.fa-file-html:before {
  content: "\e90a";
}
.fa-file-ind:before {
  content: "\e90b";
}
.fa-file-ini:before {
  content: "\e90c";
}
.fa-file-jpeg:before {
  content: "\e90d";
}
.fa-file-jsf:before {
  content: "\e90e";
}
.fa-file-midi:before {
  content: "\e90f";
}
.fa-file-mov:before {
  content: "\e910";
}
.fa-file-mp3:before {
  content: "\e911";
}
.fa-file-mpeg:before {
  content: "\e912";
}
.fa-file-pdf:before {
  content: "\e913";
}
.fa-file-png:before {
  content: "\e914";
}
.fa-file-ppt:before {
  content: "\e915";
}
.fa-file-pptx:before {
  content: "\e916";
}
.fa-file-proj:before {
  content: "\e917";
}
.fa-file-psd:before {
  content: "\e918";
}
.fa-file-pst:before {
  content: "\e919";
}
.fa-file-pub:before {
  content: "\e91a";
}
.fa-file-rar:before {
  content: "\e91b";
}
.fa-file-read:before {
  content: "\e91c";
}
.fa-file-set:before {
  content: "\e91d";
}
.fa-file-tiff:before {
  content: "\e91e";
}
.fa-file-txt:before {
  content: "\e91f";
}
.fa-file-url:before {
  content: "\e920";
}
.fa-file-vsd:before {
  content: "\e921";
}
.fa-file-wav:before {
  content: "\e922";
}
.fa-file-wma:before {
  content: "\e923";
}
.fa-file-wmv:before {
  content: "\e924";
}
.fa-file-xls:before {
  content: "\e925";
}
.fa-file-xlsx:before {
  content: "\e926";
}
.fa-file-zip:before {
  content: "\e927";
}
.fa-facebook-f:before {
  content: "\e928";
}
.fa-px:before {
  content: "\e929";
}
.fa-adjust:before {
  content: "\e92a";
}
.fa-adn:before {
  content: "\e92b";
}
.fa-align-center:before {
  content: "\e92c";
}
.fa-align-justify:before {
  content: "\e92d";
}
.fa-align-left:before {
  content: "\e92e";
}
.fa-align-right:before {
  content: "\e92f";
}
.fa-amazon:before {
  content: "\e930";
}
.fa-ambulance:before {
  content: "\e931";
}
.fa-american-sign-language-interpreting:before {
  content: "\e932";
}
.fa-anchor:before {
  content: "\e933";
}
.fa-android:before {
  content: "\e934";
}
.fa-angellist:before {
  content: "\e935";
}
.fa-angle-double-down:before {
  content: "\e936";
}
.fa-angle-double-left:before {
  content: "\e937";
}
.fa-angle-double-right:before {
  content: "\e938";
}
.fa-angle-double-up:before {
  content: "\e939";
}
.fa-angle-down:before {
  content: "\e93a";
}
.fa-angle-left:before {
  content: "\e93b";
}
.fa-angle-right:before {
  content: "\e93c";
}
.fa-angle-up:before {
  content: "\e93d";
}
.fa-apple:before {
  content: "\e93e";
}
.fa-archive:before {
  content: "\e93f";
}
.fa-area-chart:before {
  content: "\e940";
}
.fa-aret-up:before {
  content: "\e941";
}
.fa-arrow-circle-down:before {
  content: "\e942";
}
.fa-arrow-circle-left:before {
  content: "\e943";
}
.fa-arrow-circle-o-down:before {
  content: "\e944";
}
.fa-arrow-circle-o-left:before {
  content: "\e945";
}
.fa-arrow-circle-o-right:before {
  content: "\e946";
}
.fa-arrow-circle-o-up:before {
  content: "\e947";
}
.fa-arrow-circle-right:before {
  content: "\e948";
}
.fa-arrow-circle-up:before {
  content: "\e949";
}
.fa-arrow-down:before {
  content: "\e94a";
}
.fa-arrow-left:before {
  content: "\e94b";
}
.fa-arrow-right:before {
  content: "\e94c";
}
.fa-arrow-up:before {
  content: "\e94d";
}
.fa-arrows-alt:before {
  content: "\e94e";
}
.fa-arrows-h:before {
  content: "\e94f";
}
.fa-arrows-v:before {
  content: "\e950";
}
.fa-arrows:before {
  content: "\e951";
}
.fa-asl-interpreting:before {
  content: "\e952";
}
.fa-assistive-listening-systems:before {
  content: "\e953";
}
.fa-asterisk:before {
  content: "\e954";
}
.fa-at:before {
  content: "\e955";
}
.fa-audio-description:before {
  content: "\e956";
}
.fa-automobile:before {
  content: "\e957";
}
.fa-backward:before {
  content: "\e958";
}
.fa-balance-scale:before {
  content: "\e959";
}
.fa-ban:before {
  content: "\e95a";
}
.fa-bank:before {
  content: "\e95b";
}
.fa-bar-chart-o:before {
  content: "\e95c";
}
.fa-bar-chart:before {
  content: "\e95d";
}
.fa-barcode:before {
  content: "\e95e";
}
.fa-bars:before {
  content: "\e95f";
}
.fa-battery-0:before {
  content: "\e960";
}
.fa-battery-1:before {
  content: "\e961";
}
.fa-battery-2:before {
  content: "\e962";
}
.fa-battery-3:before {
  content: "\e963";
}
.fa-battery-4:before {
  content: "\e964";
}
.fa-battery-empty:before {
  content: "\e965";
}
.fa-battery-full:before {
  content: "\e966";
}
.fa-battery-half:before {
  content: "\e967";
}
.fa-battery-quarter:before {
  content: "\e968";
}
.fa-battery-three-quarters:before {
  content: "\e969";
}
.fa-bed:before {
  content: "\e96a";
}
.fa-beer:before {
  content: "\e96b";
}
.fa-behance-square:before {
  content: "\e96c";
}
.fa-behance:before {
  content: "\e96d";
}
.fa-bell-o:before {
  content: "\e96e";
}
.fa-bell-slash-o:before {
  content: "\e96f";
}
.fa-bell-slash:before {
  content: "\e970";
}
.fa-bell:before {
  content: "\e971";
}
.fa-bicycle:before {
  content: "\e972";
}
.fa-binoculars:before {
  content: "\e973";
}
.fa-birthday-cake:before {
  content: "\e974";
}
.fa-bitbucket-square:before {
  content: "\e975";
}
.fa-bitbucket:before {
  content: "\e976";
}
.fa-bitcoin:before {
  content: "\e977";
}
.fa-black-tie:before {
  content: "\e978";
}
.fa-blind:before {
  content: "\e979";
}
.fa-bluetooth-b:before {
  content: "\e97a";
}
.fa-bluetooth:before {
  content: "\e97b";
}
.fa-bold:before {
  content: "\e97c";
}
.fa-bolt:before {
  content: "\e97d";
}
.fa-bomb:before {
  content: "\e97e";
}
.fa-book:before {
  content: "\e97f";
}
.fa-bookmark-o:before {
  content: "\e980";
}
.fa-bookmark:before {
  content: "\e981";
}
.fa-braille:before {
  content: "\e982";
}
.fa-briefcase:before {
  content: "\e983";
}
.fa-btc:before {
  content: "\e984";
}
.fa-bug:before {
  content: "\e985";
}
.fa-building-o:before {
  content: "\e986";
}
.fa-building:before {
  content: "\e987";
}
.fa-bullhorn:before {
  content: "\e988";
}
.fa-bullseye:before {
  content: "\e989";
}
.fa-bus:before {
  content: "\e98a";
}
.fa-buysellads:before {
  content: "\e98b";
}
.fa-cab:before {
  content: "\e98c";
}
.fa-calculator:before {
  content: "\e98d";
}
.fa-calendar-check-o:before {
  content: "\e98e";
}
.fa-calendar-minus-o:before {
  content: "\e98f";
}
.fa-calendar-o:before {
  content: "\e990";
}
.fa-calendar-plus-o:before {
  content: "\e991";
}
.fa-calendar-times-o:before {
  content: "\e992";
}
.fa-calendar:before {
  content: "\e993";
}
.fa-camera-retro:before {
  content: "\e994";
}
.fa-camera:before {
  content: "\e995";
}
.fa-car:before {
  content: "\e996";
}
.fa-caret-down:before {
  content: "\e997";
}
.fa-caret-left:before {
  content: "\e998";
}
.fa-caret-right:before {
  content: "\e999";
}
.fa-caret-square-o-down:before {
  content: "\e99a";
}
.fa-caret-square-o-left:before {
  content: "\e99b";
}
.fa-caret-square-o-right:before {
  content: "\e99c";
}
.fa-caret-square-o-up:before {
  content: "\e99d";
}
.fa-caret-up:before {
  content: "\e99e";
}
.fa-cart-arrow-down:before {
  content: "\e99f";
}
.fa-cart-plus:before {
  content: "\e9a0";
}
.fa-cc-amex:before {
  content: "\e9a1";
}
.fa-cc-diners-club:before {
  content: "\e9a2";
}
.fa-cc-discover:before {
  content: "\e9a3";
}
.fa-cc-jcb:before {
  content: "\e9a4";
}
.fa-cc-mastercard:before {
  content: "\e9a5";
}
.fa-cc-paypal:before {
  content: "\e9a6";
}
.fa-cc-stripe:before {
  content: "\e9a7";
}
.fa-cc-visa:before {
  content: "\e9a8";
}
.fa-cc:before {
  content: "\e9a9";
}
.fa-certificate:before {
  content: "\e9aa";
}
.fa-chain-broken:before {
  content: "\e9ab";
}
.fa-chain:before {
  content: "\e9ac";
}
.fa-check-circle-o:before {
  content: "\e9ad";
}
.fa-check-circle:before {
  content: "\e9ae";
}
.fa-check-square-o:before {
  content: "\e9af";
}
.fa-check-square:before {
  content: "\e9b0";
}
.fa-check:before {
  content: "\e9b1";
}
.fa-chevron-circle-down:before {
  content: "\e9b2";
}
.fa-chevron-circle-left:before {
  content: "\e9b3";
}
.fa-chevron-circle-right:before {
  content: "\e9b4";
}
.fa-chevron-circle-up:before {
  content: "\e9b5";
}
.fa-chevron-down:before {
  content: "\e9b6";
}
.fa-chevron-left:before {
  content: "\e9b7";
}
.fa-chevron-right:before {
  content: "\e9b8";
}
.fa-chevron-up:before {
  content: "\e9b9";
}
.fa-child:before {
  content: "\e9ba";
}
.fa-chrome:before {
  content: "\e9bb";
}
.fa-circle-o-notch:before {
  content: "\e9bc";
}
.fa-circle-o:before {
  content: "\e9bd";
}
.fa-circle-thin:before {
  content: "\e9be";
}
.fa-circle:before {
  content: "\e9bf";
}
.fa-clipboard:before {
  content: "\e9c0";
}
.fa-clock-o:before {
  content: "\e9c1";
}
.fa-clone:before {
  content: "\e9c2";
}
.fa-close:before {
  content: "\e9c3";
}
.fa-cloud-download:before {
  content: "\e9c4";
}
.fa-cloud-upload:before {
  content: "\e9c5";
}
.fa-cloud:before {
  content: "\e9c6";
}
.fa-cny:before {
  content: "\e9c7";
}
.fa-code-fork:before {
  content: "\e9c8";
}
.fa-code:before {
  content: "\e9c9";
}
.fa-codepen:before {
  content: "\e9ca";
}
.fa-codiepie:before {
  content: "\e9cb";
}
.fa-coffee:before {
  content: "\e9cc";
}
.fa-cog:before {
  content: "\e9cd";
}
.fa-cogs:before {
  content: "\e9ce";
}
.fa-columns:before {
  content: "\e9cf";
}
.fa-comment-o:before {
  content: "\e9d0";
}
.fa-comment:before {
  content: "\e9d1";
}
.fa-commenting-o:before {
  content: "\e9d2";
}
.fa-commenting:before {
  content: "\e9d3";
}
.fa-comments-o:before {
  content: "\e9d4";
}
.fa-comments:before {
  content: "\e9d5";
}
.fa-compass:before {
  content: "\e9d6";
}
.fa-compress:before {
  content: "\e9d7";
}
.fa-connectdevelop:before {
  content: "\e9d8";
}
.fa-contao:before {
  content: "\e9d9";
}
.fa-copy:before {
  content: "\e9da";
}
.fa-copyright:before {
  content: "\e9db";
}
.fa-coupon:before {
  content: "\e9dc";
}
.fa-creative-commons:before {
  content: "\e9dd";
}
.fa-credit-card-alt:before {
  content: "\e9de";
}
.fa-credit-card:before {
  content: "\e9df";
}
.fa-crop:before {
  content: "\e9e0";
}
.fa-crosshairs:before {
  content: "\e9e1";
}
.fa-css3:before {
  content: "\e9e2";
}
.fa-cube:before {
  content: "\e9e3";
}
.fa-cubes:before {
  content: "\e9e4";
}
.fa-cursor:before {
  content: "\e9e5";
}
.fa-cut:before {
  content: "\e9e6";
}
.fa-cutlery:before {
  content: "\e9e7";
}
.fa-dashboard:before {
  content: "\e9e8";
}
.fa-dashcube:before {
  content: "\e9e9";
}
.fa-database:before {
  content: "\e9ea";
}
.fa-deaf:before {
  content: "\e9eb";
}
.fa-deafness:before {
  content: "\e9ec";
}
.fa-dedent:before {
  content: "\e9ed";
}
.fa-delicious:before {
  content: "\e9ee";
}
.fa-desktop:before {
  content: "\e9ef";
}
.fa-deviantart:before {
  content: "\e9f0";
}
.fa-diamond:before {
  content: "\e9f1";
}
.fa-digg:before {
  content: "\e9f2";
}
.fa-dollar:before {
  content: "\e9f3";
}
.fa-dot-circle-o:before {
  content: "\e9f4";
}
.fa-download:before {
  content: "\e9f5";
}
.fa-dribbble:before {
  content: "\e9f6";
}
.fa-dropbox:before {
  content: "\e9f7";
}
.fa-drupal:before {
  content: "\e9f8";
}
.fa-edge:before {
  content: "\e9f9";
}
.fa-edit:before {
  content: "\e9fa";
}
.fa-eject:before {
  content: "\e9fb";
}
.fa-ellipsis-h:before {
  content: "\e9fc";
}
.fa-ellipsis-v:before {
  content: "\e9fd";
}
.fa-empire:before {
  content: "\e9fe";
}
.fa-envelope-o:before {
  content: "\e9ff";
}
.fa-envelope-square:before {
  content: "\ea00";
}
.fa-envelope:before {
  content: "\ea01";
}
.fa-envira:before {
  content: "\ea02";
}
.fa-eraser:before {
  content: "\ea03";
}
.fa-eur:before {
  content: "\ea04";
}
.fa-euro:before {
  content: "\ea05";
}
.fa-exchange:before {
  content: "\ea06";
}
.fa-exclamation-circle:before {
  content: "\ea07";
}
.fa-exclamation-triangle:before {
  content: "\ea08";
}
.fa-exclamation:before {
  content: "\ea09";
}
.fa-expand:before {
  content: "\ea0a";
}
.fa-expeditedssl:before {
  content: "\ea0b";
}
.fa-external-link-square:before {
  content: "\ea0c";
}
.fa-external-link:before {
  content: "\ea0d";
}
.fa-eye-slash:before {
  content: "\ea0e";
}
.fa-eye:before {
  content: "\ea0f";
}
.fa-eyedropper:before {
  content: "\ea10";
}
.fa-fa:before {
  content: "\ea11";
}
.fa-facebook:before {
  content: "\ea12";
}
.fa-facebook-official:before {
  content: "\ea13";
}
.fa-facebook-square:before {
  content: "\ea14";
}
.fa-fast-backward:before {
  content: "\ea15";
}
.fa-fast-forward:before {
  content: "\ea16";
}
.fa-fax:before {
  content: "\ea17";
}
.fa-feed:before {
  content: "\ea18";
}
.fa-female:before {
  content: "\ea19";
}
.fa-fighter-jet:before {
  content: "\ea1a";
}
.fa-file-archive-o:before {
  content: "\ea1b";
}
.fa-file-audio-o:before {
  content: "\ea1c";
}
.fa-file-code-o:before {
  content: "\ea1d";
}
.fa-file-excel-o:before {
  content: "\ea1e";
}
.fa-file-image-o:before {
  content: "\ea1f";
}
.fa-file-movie-o:before {
  content: "\ea20";
}
.fa-file-o:before {
  content: "\ea21";
}
.fa-file-pdf-o:before {
  content: "\ea22";
}
.fa-file-photo-o:before {
  content: "\ea23";
}
.fa-file-picture-o:before {
  content: "\ea24";
}
.fa-file-powerpoint-o:before {
  content: "\ea25";
}
.fa-file-sound-o:before {
  content: "\ea26";
}
.fa-file-text-o:before {
  content: "\ea27";
}
.fa-file-text:before {
  content: "\ea28";
}
.fa-file-video-o:before {
  content: "\ea29";
}
.fa-file-word-o:before {
  content: "\ea2a";
}
.fa-file-zip-o:before {
  content: "\ea2b";
}
.fa-file:before {
  content: "\ea2c";
}
.fa-files-o:before {
  content: "\ea2d";
}
.fa-film:before {
  content: "\ea2e";
}
.fa-filter:before {
  content: "\ea2f";
}
.fa-fire-extinguisher:before {
  content: "\ea30";
}
.fa-fire:before {
  content: "\ea31";
}
.fa-firefox:before {
  content: "\ea32";
}
.fa-first-order:before {
  content: "\ea33";
}
.fa-flag-checkered:before {
  content: "\ea34";
}
.fa-flag-o:before {
  content: "\ea35";
}
.fa-flag:before {
  content: "\ea36";
}
.fa-flash:before {
  content: "\ea37";
}
.fa-flask:before {
  content: "\ea38";
}
.fa-flickr:before {
  content: "\ea39";
}
.fa-floppy-o:before {
  content: "\ea3a";
}
.fa-folder-o:before {
  content: "\ea3b";
}
.fa-folder-open-o:before {
  content: "\ea3c";
}
.fa-folder-open:before {
  content: "\ea3d";
}
.fa-folder:before {
  content: "\ea3e";
}
.fa-font-awesome:before {
  content: "\ea3f";
}
.fa-font:before {
  content: "\ea40";
}
.fa-fonticons:before {
  content: "\ea41";
}
.fa-fort-awesome:before {
  content: "\ea42";
}
.fa-forumbee:before {
  content: "\ea43";
}
.fa-forward:before {
  content: "\ea44";
}
.fa-foursquare:before {
  content: "\ea45";
}
.fa-frown-o:before {
  content: "\ea46";
}
.fa-futbol-o:before {
  content: "\ea47";
}
.fa-gamepad:before {
  content: "\ea48";
}
.fa-gavel:before {
  content: "\ea49";
}
.fa-gbp:before {
  content: "\ea4a";
}
.fa-ge:before {
  content: "\ea4b";
}
.fa-gear:before {
  content: "\ea4c";
}
.fa-gears:before {
  content: "\ea4d";
}
.fa-genderless:before {
  content: "\ea4e";
}
.fa-get-pocket:before {
  content: "\ea4f";
}
.fa-gg:before {
  content: "\ea50";
}
.fa-gg-circle:before {
  content: "\ea51";
}
.fa-gift:before {
  content: "\ea52";
}
.fa-git-square:before {
  content: "\ea53";
}
.fa-git:before {
  content: "\ea54";
}
.fa-github-alt:before {
  content: "\ea55";
}
.fa-github-square:before {
  content: "\ea56";
}
.fa-github:before {
  content: "\ea57";
}
.fa-gitlab:before {
  content: "\ea58";
}
.fa-gittip:before {
  content: "\ea59";
}
.fa-glass:before {
  content: "\ea5a";
}
.fa-glide-g:before {
  content: "\ea5b";
}
.fa-glide:before {
  content: "\ea5c";
}
.fa-globe:before {
  content: "\ea5d";
}
.fa-google-plus---副本:before {
  content: "\ea5e";
}
.fa-google-plus-circle:before {
  content: "\ea5f";
}
.fa-google-plus-official:before {
  content: "\ea60";
}
.fa-google-plus-square:before {
  content: "\ea61";
}
.fa-google-plus:before {
  content: "\ea62";
}
.fa-google-wallet:before {
  content: "\ea63";
}
.fa-google:before {
  content: "\ea64";
}
.fa-graduation-cap:before {
  content: "\ea65";
}
.fa-gratipay:before {
  content: "\ea66";
}
.fa-group:before {
  content: "\ea67";
}
.fa-h-square:before {
  content: "\ea68";
}
.fa-hacker-news:before {
  content: "\ea69";
}
.fa-hand-grab-o:before {
  content: "\ea6a";
}
.fa-hand-lizard-o:before {
  content: "\ea6b";
}
.fa-hand-o-down:before {
  content: "\ea6c";
}
.fa-hand-o-left:before {
  content: "\ea6d";
}
.fa-hand-o-right:before {
  content: "\ea6e";
}
.fa-hand-o-up:before {
  content: "\ea6f";
}
.fa-hand-paper-o:before {
  content: "\ea70";
}
.fa-hand-peace-o:before {
  content: "\ea71";
}
.fa-hand-pointer-o:before {
  content: "\ea72";
}
.fa-hand-rock-o:before {
  content: "\ea73";
}
.fa-hand-scissors-o:before {
  content: "\ea74";
}
.fa-hand-spock-o:before {
  content: "\ea75";
}
.fa-hand-stop-o:before {
  content: "\ea76";
}
.fa-hard-of-hearing:before {
  content: "\ea77";
}
.fa-hashtag:before {
  content: "\ea78";
}
.fa-hdd-o:before {
  content: "\ea79";
}
.fa-header:before {
  content: "\ea7a";
}
.fa-headphones:before {
  content: "\ea7b";
}
.fa-heart-o:before {
  content: "\ea7c";
}
.fa-heart:before {
  content: "\ea7d";
}
.fa-heartbeat:before {
  content: "\ea7e";
}
.fa-history:before {
  content: "\ea7f";
}
.fa-home:before {
  content: "\ea80";
}
.fa-hospital-o:before {
  content: "\ea81";
}
.fa-hotel:before {
  content: "\ea82";
}
.fa-hourglass-1:before {
  content: "\ea83";
}
.fa-hourglass-2:before {
  content: "\ea84";
}
.fa-hourglass-3:before {
  content: "\ea85";
}
.fa-hourglass-end:before {
  content: "\ea86";
}
.fa-hourglass-half:before {
  content: "\ea87";
}
.fa-hourglass-o:before {
  content: "\ea88";
}
.fa-hourglass-start:before {
  content: "\ea89";
}
.fa-hourglass:before {
  content: "\ea8a";
}
.fa-houzz:before {
  content: "\ea8b";
}
.fa-html5:before {
  content: "\ea8c";
}
.fa-i-cursor:before {
  content: "\ea8d";
}
.fa-ils:before {
  content: "\ea8e";
}
.fa-image:before {
  content: "\ea8f";
}
.fa-inbox:before {
  content: "\ea90";
}
.fa-indent:before {
  content: "\ea91";
}
.fa-industry:before {
  content: "\ea92";
}
.fa-info-01:before {
  content: "\ea93";
}
.fa-info-circle:before {
  content: "\ea94";
}
.fa-info:before {
  content: "\ea95";
}
.fa-inr:before {
  content: "\ea96";
}
.fa-instagram:before {
  content: "\ea97";
}
.fa-institution:before {
  content: "\ea98";
}
.fa-internet-explorer:before {
  content: "\ea99";
}
.fa-intersex:before {
  content: "\ea9a";
}
.fa-ioxhost:before {
  content: "\ea9b";
}
.fa-italic:before {
  content: "\ea9c";
}
.fa-joomla:before {
  content: "\ea9d";
}
.fa-jpy:before {
  content: "\ea9e";
}
.fa-jsfiddle:before {
  content: "\ea9f";
}
.fa-key:before {
  content: "\eaa0";
}
.fa-keyboard-o:before {
  content: "\eaa1";
}
.fa-krw:before {
  content: "\eaa2";
}
.fa-language:before {
  content: "\eaa3";
}
.fa-laptop:before {
  content: "\eaa4";
}
.fa-lastfm-square:before {
  content: "\eaa5";
}
.fa-lastfm:before {
  content: "\eaa6";
}
.fa-leaf:before {
  content: "\eaa7";
}
.fa-leanpub:before {
  content: "\eaa8";
}
.fa-legal:before {
  content: "\eaa9";
}
.fa-lemon-o:before {
  content: "\eaaa";
}
.fa-level-down:before {
  content: "\eaab";
}
.fa-level-up:before {
  content: "\eaac";
}
.fa-life-bouy:before {
  content: "\eaad";
}
.fa-life-buoy:before {
  content: "\eaae";
}
.fa-life-ring:before {
  content: "\eaaf";
}
.fa-life-saver:before {
  content: "\eab0";
}
.fa-lightbulb-o:before {
  content: "\eab1";
}
.fa-line-chart:before {
  content: "\eab2";
}
.fa-link:before {
  content: "\eab3";
}
.fa-linkedin-square:before {
  content: "\eab4";
}
.fa-linkedin:before {
  content: "\eab5";
}
.fa-linux:before {
  content: "\eab6";
}
.fa-list-alt:before {
  content: "\eab7";
}
.fa-list-ol:before {
  content: "\eab8";
}
.fa-list-ul:before {
  content: "\eab9";
}
.fa-list:before {
  content: "\eaba";
}
.fa-location-arrow:before {
  content: "\eabb";
}
.fa-lock:before {
  content: "\eabc";
}
.fa-long-arrow-down:before {
  content: "\eabd";
}
.fa-long-arrow-left:before {
  content: "\eabe";
}
.fa-long-arrow-right:before {
  content: "\eabf";
}
.fa-long-arrow-up:before {
  content: "\eac0";
}
.fa-low-vision:before {
  content: "\eac1";
}
.fa-magic:before {
  content: "\eac2";
}
.fa-magnet:before {
  content: "\eac3";
}
.fa-mail-forward:before {
  content: "\eac4";
}
.fa-mail-reply-all:before {
  content: "\eac5";
}
.fa-mail-reply:before {
  content: "\eac6";
}
.fa-male:before {
  content: "\eac7";
}
.fa-map-marker:before {
  content: "\eac8";
}
.fa-map-o:before {
  content: "\eac9";
}
.fa-map-pin:before {
  content: "\eaca";
}
.fa-map-signs:before {
  content: "\eacb";
}
.fa-map:before {
  content: "\eacc";
}
.fa-mars-double:before {
  content: "\eacd";
}
.fa-mars-stroke-h:before {
  content: "\eace";
}
.fa-mars-stroke-v:before {
  content: "\eacf";
}
.fa-mars-stroke:before {
  content: "\ead0";
}
.fa-mars:before {
  content: "\ead1";
}
.fa-maxcdn:before {
  content: "\ead2";
}
.fa-meanpath:before {
  content: "\ead3";
}
.fa-medium:before {
  content: "\ead4";
}
.fa-medkit:before {
  content: "\ead5";
}
.fa-meh-o:before {
  content: "\ead6";
}
.fa-mercury:before {
  content: "\ead7";
}
.fa-microphone:before {
  content: "\ead8";
}
.fa-microphone-slash:before {
  content: "\ead9";
}
.fa-minus-circle:before {
  content: "\eada";
}
.fa-minus-square-o:before {
  content: "\eadb";
}
.fa-minus-square:before {
  content: "\eadc";
}
.fa-minus:before {
  content: "\eadd";
}
.fa-mixcloud:before {
  content: "\eade";
}
.fa-mobile-phone:before {
  content: "\eadf";
}
.fa-mobile:before {
  content: "\eae0";
}
.fa-modx:before {
  content: "\eae1";
}
.fa-money:before {
  content: "\eae2";
}
.fa-moon-o:before {
  content: "\eae3";
}
.fa-mortar-board:before {
  content: "\eae4";
}
.fa-motorcycle:before {
  content: "\eae5";
}
.fa-mouse-pointer:before {
  content: "\eae6";
}
.fa-music:before {
  content: "\eae7";
}
.fa-navicon:before {
  content: "\eae8";
}
.fa-neuter:before {
  content: "\eae9";
}
.fa-newspaper-o:before {
  content: "\eaea";
}
.fa-nlock:before {
  content: "\eaeb";
}
.fa-object-group:before {
  content: "\eaec";
}
.fa-object-ungroup:before {
  content: "\eaed";
}
.fa-odnoklassniki-square:before {
  content: "\eaee";
}
.fa-odnoklassniki:before {
  content: "\eaef";
}
.fa-opencart:before {
  content: "\eaf0";
}
.fa-openid:before {
  content: "\eaf1";
}
.fa-opera:before {
  content: "\eaf2";
}
.fa-optin-monster、:before {
  content: "\eaf3";
}
.fa-optin-monster:before {
  content: "\eaf4";
}
.fa-order:before {
  content: "\eaf5";
}
.fa-outdent:before {
  content: "\eaf6";
}
.fa-pagelines:before {
  content: "\eaf7";
}
.fa-paint-brush:before {
  content: "\eaf8";
}
.fa-paper-plane-o:before {
  content: "\eaf9";
}
.fa-paper-plane:before {
  content: "\eafa";
}
.fa-paperclip:before {
  content: "\eafb";
}
.fa-paragraph:before {
  content: "\eafc";
}
.fa-paste:before {
  content: "\eafd";
}
.fa-pause-circle-o:before {
  content: "\eafe";
}
.fa-pause-circle:before {
  content: "\eaff";
}
.fa-pause:before {
  content: "\eb00";
}
.fa-paw:before {
  content: "\eb01";
}
.fa-paypal:before {
  content: "\eb02";
}
.fa-pencil-square-o:before {
  content: "\eb03";
}
.fa-pencil-square:before {
  content: "\eb04";
}
.fa-pencil:before {
  content: "\eb05";
}
.fa-percent:before {
  content: "\eb06";
}
.fa-phone-square:before {
  content: "\eb07";
}
.fa-phone:before {
  content: "\eb08";
}
.fa-photo:before {
  content: "\eb09";
}
.fa-picture-o:before {
  content: "\eb0a";
}
.fa-pie-chart:before {
  content: "\eb0b";
}
.fa-pied-piper-alt:before {
  content: "\eb0c";
}
.fa-pied-piper-pp:before {
  content: "\eb0d";
}
.fa-pied-piper:before {
  content: "\eb0e";
}
.fa-pinterest:before {
  content: "\eb0f";
}
.fa-pinterest-p:before {
  content: "\eb10";
}
.fa-pinterest-square:before {
  content: "\eb11";
}
.fa-plane:before {
  content: "\eb12";
}
.fa-play-circle-o:before {
  content: "\eb13";
}
.fa-play-circle:before {
  content: "\eb14";
}
.fa-play:before {
  content: "\eb15";
}
.fa-plug:before {
  content: "\eb16";
}
.fa-plus-circle:before {
  content: "\eb17";
}
.fa-plus-square-o:before {
  content: "\eb18";
}
.fa-plus-square:before {
  content: "\eb19";
}
.fa-plus:before {
  content: "\eb1a";
}
.fa-power-off:before {
  content: "\eb1b";
}
.fa-print:before {
  content: "\eb1c";
}
.fa-product-hunt:before {
  content: "\eb1d";
}
.fa-puzzle-piece:before {
  content: "\eb1e";
}
.fa-qq:before {
  content: "\eb1f";
}
.fa-qrcode:before {
  content: "\eb20";
}
.fa-question-circle-o:before {
  content: "\eb21";
}
.fa-question-circle:before {
  content: "\eb22";
}
.fa-question:before {
  content: "\eb23";
}
.fa-quote-left:before {
  content: "\eb24";
}
.fa-quote-right:before {
  content: "\eb25";
}
.fa-ra:before {
  content: "\eb26";
}
.fa-random:before {
  content: "\eb27";
}
.fa-rebel:before {
  content: "\eb28";
}
.fa-recharge:before {
  content: "\eb29";
}
.fa-recycle:before {
  content: "\eb2a";
}
.fa-redbag:before {
  content: "\eb2b";
}
.fa-reddit-alien:before {
  content: "\eb2c";
}
.fa-reddit-square:before {
  content: "\eb2d";
}
.fa-reddit:before {
  content: "\eb2e";
}
.fa-refresh:before {
  content: "\eb2f";
}
.fa-registered:before {
  content: "\eb30";
}
.fa-remove:before {
  content: "\eb31";
}
.fa-renren:before {
  content: "\eb32";
}
.fa-reorder、:before {
  content: "\eb33";
}
.fa-reorder:before {
  content: "\eb34";
}
.fa-repeat:before {
  content: "\eb35";
}
.fa-reply-all:before {
  content: "\eb36";
}
.fa-reply:before {
  content: "\eb37";
}
.fa-resistance:before {
  content: "\eb38";
}
.fa-retweet:before {
  content: "\eb39";
}
.fa-rmb:before {
  content: "\eb3a";
}
.fa-road:before {
  content: "\eb3b";
}
.fa-rocket:before {
  content: "\eb3c";
}
.fa-rotate-left:before {
  content: "\eb3d";
}
.fa-rotate-right:before {
  content: "\eb3e";
}
.fa-rouble:before {
  content: "\eb3f";
}
.fa-rss-square:before {
  content: "\eb40";
}
.fa-rss:before {
  content: "\eb41";
}
.fa-rub:before {
  content: "\eb42";
}
.fa-ruble:before {
  content: "\eb43";
}
.fa-rupee:before {
  content: "\eb44";
}
.fa-safari:before {
  content: "\eb45";
}
.fa-save:before {
  content: "\eb46";
}
.fa-scan:before {
  content: "\eb47";
}
.fa-scissors:before {
  content: "\eb48";
}
.fa-scribd:before {
  content: "\eb49";
}
.fa-search-minus:before {
  content: "\eb4a";
}
.fa-search-plus:before {
  content: "\eb4b";
}
.fa-search:before {
  content: "\eb4c";
}
.fa-sellsy:before {
  content: "\eb4d";
}
.fa-send-o:before {
  content: "\eb4e";
}
.fa-send:before {
  content: "\eb4f";
}
.fa-server:before {
  content: "\eb50";
}
.fa-share-alt-square:before {
  content: "\eb51";
}
.fa-share-alt:before {
  content: "\eb52";
}
.fa-share-square-o:before {
  content: "\eb53";
}
.fa-share-square:before {
  content: "\eb54";
}
.fa-share:before {
  content: "\eb55";
}
.fa-shekel:before {
  content: "\eb56";
}
.fa-sheqel:before {
  content: "\eb57";
}
.fa-shield:before {
  content: "\eb58";
}
.fa-ship:before {
  content: "\eb59";
}
.fa-shirtsinbulk:before {
  content: "\eb5a";
}
.fa-shopping-bag:before {
  content: "\eb5b";
}
.fa-shopping-basket:before {
  content: "\eb5c";
}
.fa-shopping-cart-、:before {
  content: "\eb5d";
}
.fa-shopping-cart:before {
  content: "\eb5e";
}
.fa-sign-in:before {
  content: "\eb5f";
}
.fa-sign-language:before {
  content: "\eb60";
}
.fa-sign-out:before {
  content: "\eb61";
}
.fa-signal:before {
  content: "\eb62";
}
.fa-signing:before {
  content: "\eb63";
}
.fa-simplybuilt:before {
  content: "\eb64";
}
.fa-sitemap:before {
  content: "\eb65";
}
.fa-skyatlas:before {
  content: "\eb66";
}
.fa-skype:before {
  content: "\eb67";
}
.fa-slack:before {
  content: "\eb68";
}
.fa-sliders:before {
  content: "\eb69";
}
.fa-slideshare:before {
  content: "\eb6a";
}
.fa-smile-o:before {
  content: "\eb6b";
}
.fa-snapchat-ghost:before {
  content: "\eb6c";
}
.fa-snapchat-square:before {
  content: "\eb6d";
}
.fa-snapchat:before {
  content: "\eb6e";
}
.fa-soccer-ball-o:before {
  content: "\eb6f";
}
.fa-sort-alpha-asc:before {
  content: "\eb70";
}
.fa-sort-alpha-desc:before {
  content: "\eb71";
}
.fa-sort-amount-asc:before {
  content: "\eb72";
}
.fa-sort-amount-desc:before {
  content: "\eb73";
}
.fa-sort-asc:before {
  content: "\eb74";
}
.fa-sort-desc:before {
  content: "\eb75";
}
.fa-sort-down:before {
  content: "\eb76";
}
.fa-sort-numeric-asc:before {
  content: "\eb77";
}
.fa-sort-numeric-desc:before {
  content: "\eb78";
}
.fa-sort-up:before {
  content: "\eb79";
}
.fa-sort:before {
  content: "\eb7a";
}
.fa-soundcloud:before {
  content: "\eb7b";
}
.fa-space-shuttle:before {
  content: "\eb7c";
}
.fa-spinner:before {
  content: "\eb7d";
}
.fa-spoon:before {
  content: "\eb7e";
}
.fa-spotify:before {
  content: "\eb7f";
}
.fa-square-o:before {
  content: "\eb80";
}
.fa-square:before {
  content: "\eb81";
}
.fa-stack-exchange:before {
  content: "\eb82";
}
.fa-stack-overflow:before {
  content: "\eb83";
}
.fa-star-half-empty:before {
  content: "\eb84";
}
.fa-star-half-full:before {
  content: "\eb85";
}
.fa-star-half-o:before {
  content: "\eb86";
}
.fa-star-half:before {
  content: "\eb87";
}
.fa-star-o:before {
  content: "\eb88";
}
.fa-star:before {
  content: "\eb89";
}
.fa-steam-square:before {
  content: "\eb8a";
}
.fa-steam:before {
  content: "\eb8b";
}
.fa-step-backward:before {
  content: "\eb8c";
}
.fa-step-forward:before {
  content: "\eb8d";
}
.fa-stethoscope:before {
  content: "\eb8e";
}
.fa-sticky-note-o:before {
  content: "\eb8f";
}
.fa-sticky-note:before {
  content: "\eb90";
}
.fa-stop-circle-o:before {
  content: "\eb91";
}
.fa-stop-circle:before {
  content: "\eb92";
}
.fa-stop:before {
  content: "\eb93";
}
.fa-street-view:before {
  content: "\eb94";
}
.fa-strikethrough:before {
  content: "\eb95";
}
.fa-stumbleupon-circle:before {
  content: "\eb96";
}
.fa-stumbleupon:before {
  content: "\eb97";
}
.fa-subscript:before {
  content: "\eb98";
}
.fa-subway:before {
  content: "\eb99";
}
.fa-suitcase:before {
  content: "\eb9a";
}
.fa-sun-o:before {
  content: "\eb9b";
}
.fa-superscript:before {
  content: "\eb9c";
}
.fa-support:before {
  content: "\eb9d";
}
.fa-support-】:before {
  content: "\eb9e";
}
.fa-table:before {
  content: "\eb9f";
}
.fa-tablet:before {
  content: "\eba0";
}
.fa-tachometer:before {
  content: "\eba1";
}
.fa-tag:before {
  content: "\eba2";
}
.fa-tags:before {
  content: "\eba3";
}
.fa-tasks:before {
  content: "\eba4";
}
.fa-taxi:before {
  content: "\eba5";
}
.fa-television:before {
  content: "\eba6";
}
.fa-tencent-weibo:before {
  content: "\eba7";
}
.fa-terminal:before {
  content: "\eba8";
}
.fa-text-height:before {
  content: "\eba9";
}
.fa-text-width:before {
  content: "\ebaa";
}
.fa-th-large:before {
  content: "\ebab";
}
.fa-th-list:before {
  content: "\ebac";
}
.fa-th:before {
  content: "\ebad";
}
.fa-themeisle:before {
  content: "\ebae";
}
.fa-thumb-tack:before {
  content: "\ebaf";
}
.fa-thumbs-down:before {
  content: "\ebb0";
}
.fa-thumbs-o-down:before {
  content: "\ebb1";
}
.fa-thumbs-o-up:before {
  content: "\ebb2";
}
.fa-thumbs-up:before {
  content: "\ebb3";
}
.fa-ticket:before {
  content: "\ebb4";
}
.fa-times-circle:before {
  content: "\ebb5";
}
.fa-times-circle-o:before {
  content: "\ebb6";
}
.fa-times:before {
  content: "\ebb7";
}
.fa-tint:before {
  content: "\ebb8";
}
.fa-toggle-down:before {
  content: "\ebb9";
}
.fa-toggle-left:before {
  content: "\ebba";
}
.fa-toggle-off:before {
  content: "\ebbb";
}
.fa-toggle-on:before {
  content: "\ebbc";
}
.fa-toggle-right:before {
  content: "\ebbd";
}
.fa-toggle-up:before {
  content: "\ebbe";
}
.fa-toggle-up2:before {
  content: "\ebbf";
}
.fa-trademark:before {
  content: "\ebc0";
}
.fa-train:before {
  content: "\ebc1";
}
.fa-transfer:before {
  content: "\ebc2";
}
.fa-transgender-alt:before {
  content: "\ebc3";
}
.fa-transgender:before {
  content: "\ebc4";
}
.fa-trash-o:before {
  content: "\ebc5";
}
.fa-trash:before {
  content: "\ebc6";
}
.fa-tree:before {
  content: "\ebc7";
}
.fa-trello:before {
  content: "\ebc8";
}
.fa-tripadvisor:before {
  content: "\ebc9";
}
.fa-trophy:before {
  content: "\ebca";
}
.fa-truck:before {
  content: "\ebcb";
}
.fa-try:before {
  content: "\ebcc";
}
.fa-tty:before {
  content: "\ebcd";
}
.fa-tumblr-square:before {
  content: "\ebce";
}
.fa-tumblr:before {
  content: "\ebcf";
}
.fa-turkish-lira:before {
  content: "\ebd0";
}
.fa-tv:before {
  content: "\ebd1";
}
.fa-twitch:before {
  content: "\ebd2";
}
.fa-twitter-square:before {
  content: "\ebd3";
}
.fa-twitter:before {
  content: "\ebd4";
}
.fa-umbrella:before {
  content: "\ebd5";
}
.fa-underline:before {
  content: "\ebd6";
}
.fa-undo:before {
  content: "\ebd7";
}
.fa-universal-access:before {
  content: "\ebd8";
}
.fa-university:before {
  content: "\ebd9";
}
.fa-unlink:before {
  content: "\ebda";
}
.fa-unlock-alt:before {
  content: "\ebdb";
}
.fa-unlock:before {
  content: "\ebdc";
}
.fa-unsorted:before {
  content: "\ebdd";
}
.fa-upload:before {
  content: "\ebde";
}
.fa-usb:before {
  content: "\ebdf";
}
.fa-usd:before {
  content: "\ebe0";
}
.fa-user-md:before {
  content: "\ebe1";
}
.fa-user-plus:before {
  content: "\ebe2";
}
.fa-user-secret:before {
  content: "\ebe3";
}
.fa-user-times:before {
  content: "\ebe4";
}
.fa-user:before {
  content: "\ebe5";
}
.fa-users:before {
  content: "\ebe6";
}
.fa-venus-double:before {
  content: "\ebe7";
}
.fa-venus-mars:before {
  content: "\ebe8";
}
.fa-venus:before {
  content: "\ebe9";
}
.fa-viacoin:before {
  content: "\ebea";
}
.fa-viadeo-square:before {
  content: "\ebeb";
}
.fa-viadeo:before {
  content: "\ebec";
}
.fa-video-camera:before {
  content: "\ebed";
}
.fa-vimeo-square:before {
  content: "\ebee";
}
.fa-vimeo:before {
  content: "\ebef";
}
.fa-vine:before {
  content: "\ebf0";
}
.fa-vk:before {
  content: "\ebf1";
}
.fa-volume-control-phone:before {
  content: "\ebf2";
}
.fa-volume-down:before {
  content: "\ebf3";
}
.fa-volume-off:before {
  content: "\ebf4";
}
.fa-volume-up:before {
  content: "\ebf5";
}
.fa-wallet:before {
  content: "\ebf6";
}
.fa-warning:before {
  content: "\ebf7";
}
.fa-wechat:before {
  content: "\ebf8";
}
.fa-weibo:before {
  content: "\ebf9";
}
.fa-weixin:before {
  content: "\ebfa";
}
.fa-whatsapp:before {
  content: "\ebfb";
}
.fa-wheelchair-alt:before {
  content: "\ebfc";
}
.fa-wheelchair:before {
  content: "\ebfd";
}
.fa-wifi:before {
  content: "\ebfe";
}
.fa-wikipedia-w:before {
  content: "\ebff";
}
.fa-windows:before {
  content: "\ec00";
}
.fa-withdraw:before {
  content: "\ec01";
}
.fa-won:before {
  content: "\ec02";
}
.fa-wordpress:before {
  content: "\ec03";
}
.fa-wpbeginner:before {
  content: "\ec04";
}
.fa-wpforms:before {
  content: "\ec05";
}
.fa-wrench:before {
  content: "\ec06";
}
.fa-xing:before {
  content: "\ec07";
}
.fa-xing-square:before {
  content: "\ec08";
}
.fa-y-combinator-square:before {
  content: "\ec09";
}
.fa-y-combinator:before {
  content: "\ec0a";
}
.fa-yahoo:before {
  content: "\ec0b";
}
.fa-yc-square:before {
  content: "\ec0c";
}
.fa-yc:before {
  content: "\ec0d";
}
.fa-yelp:before {
  content: "\ec0e";
}
.fa-yen:before {
  content: "\ec0f";
}
.fa-yoast:before {
  content: "\ec10";
}
.fa-youtube-play:before {
  content: "\ec11";
}
.fa-youtube-square:before {
  content: "\ec12";
}
.fa-youtube:before {
  content: "\ec13";
}
