.text-inherit {
  &,
  &:hover,
  &:focus {
    color: inherit;
    text-decoration: inherit;
  }
}
.text-overflow {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
