/*******************************************************
Variables
*******************************************************/
@white:#FFF;
// Sizes
@scrubber-width-default:20px;
@scrubber-width-sm:10px;
@scrubber-width-md:30px;
@scrubber-width-lg:40px;


// Scrubber text
// - off
@slider-text-unchecked:"off";
@slider-text-unchecked-color:#000;
// - on
@slider-text-checked:"on";
@slider-text-checked-color:#fff;

/*******************************************************
Animation
*******************************************************/

@keyframes popIn {
    0%   {transform: scale(1,1);}
    25%  {transform: scale(1.2,1);}
    50%  {transform: scale(1.4,1);}
    100% {transform: scale(1,1);}
}

@keyframes popOut {
    0%   {transform: scale(1,1);}
    25%  {transform: scale(1.2,1);}
    50%  {transform: scale(1.4,1);}
    100% {transform: scale(1,1);}
}

@keyframes splashIn {
    0%   {transform: scale(1); opacity:1;}
    25%  {transform: scale(1.1); opacity:.8}
    50%  {transform: scale(1.1); opacity:.9;}
    100% {transform: scale(1); opacity:1;}
}
@keyframes splashOut {
    0%   {transform: scale(1); opacity:1;}
    25%  {transform: scale(1); opacity:.8}
    50%  {transform: scale(1); opacity:.9;}
    100% {transform: scale(.5); opacity:1;}
}


/*******************************************************
Main Slider basics
*******************************************************/

.checkbox-toggle {
    label {
        padding-left: 10px;
    }

    input{
        display:block;
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        width:0%;
        height:0%;
        margin:0 0;
        cursor:pointer;
        .opacity(0);


        + span {
            cursor:pointer;
            user-select: none;

            &:before {
                position:absolute;
                left:0px;
                display:inline-block;
            }

            > h4 {
                display:inline;
            }
        }
    }
}

/*******************************************************
Main Slider
*******************************************************/

.checkbox-slider {

    .checkbox-toggle;

    input{

        + span {
            padding-left:@scrubber-width-default * 2;

            &:before {
                content:"";
                height:@scrubber-width-default;
                width:@scrubber-width-default * 2;
                background:rgba(100,100,100,.2);
                box-shadow:inset 0 0 5px rgba(0,0,0,.8);
                transition: background .2s ease-out;
            }
            // Off State
            &:after {
                width:@scrubber-width-default;
                height:@scrubber-width-default;
                position:absolute;
                left:0px;
                top:0;
                display:block;
                background:@white;
                transition: margin-left 0.1s ease-in-out;
                text-align:center;
                font-weight:bold;
                content:"";
            }
        }
        // On state
        &:checked + span:after {
            margin-left: @scrubber-width-default;
            content:"";
        }
        &:checked + span:before {
            transition: background .2s ease-in;
        }

    }

}


/*******************************************************
Slider default
*******************************************************/

.checkbox-slider--default {

    .checkbox-slider;

    input + span:after {
        background:@white;
        border:solid transparent 1px;
        background-clip:content-box;
    }

    input:checked + span:after {

        background:@brand-success;
        border:solid transparent 1px;
        background-clip:content-box;
    }

}

/*******************************************************
Slider default rounded
*******************************************************/

.checkbox-slider--a-rounded {

    .checkbox-slider--default;

    input + span:after,
    input + span:before{
        border-radius:@border-radius-base;
    }

    input + span:after,
    input:checked + span:after{
        border:solid transparent 2px;
        background-clip:content-box;
    }
}

/*******************************************************
Slider default rounded Sizes
*******************************************************/

.checkbox-slider--a-rounded{

    &.checkbox-slider-sm{

        input + span:before,
        input + span:after {
            border-radius:@border-radius-small;
        }
    }

    &.checkbox-slider-md{

        input + span:before,
        input + span:after{
            border-radius:@border-radius-base;
        }
    }

    &.checkbox-slider-lg{

        input + span:before,
        input + span:after{

            border-radius:@border-radius-large;
        }
    }

}

/*******************************************************
Slider A
*******************************************************/

.checkbox-slider--a {

    .checkbox-slider;

    input{
        + span {
            padding-left:@scrubber-width-default * 3;
        }

        + span:before {
            content:"";
            width:@scrubber-width-default * 3;
        }

        + span:after{
            width:@scrubber-width-default * 2;
            font-size:@scrubber-width-default / 2;
            color:@slider-text-unchecked-color;
            content:@slider-text-unchecked;
            border:solid transparent 1px;
            background-clip:content-box;
        }

        &:checked + span:after {
            content:@slider-text-checked;
            color:@slider-text-checked-color;
            background:@brand-success;
            border:solid transparent 1px;
            background-clip:content-box;
        }
    }
}

/*******************************************************
Slider A SIZES
*******************************************************/

.checkbox-slider--a {

    &.checkbox-slider-sm{

        input + span {
            padding-left:@scrubber-width-sm * 3;
        }

        input + span:before {
            width:@scrubber-width-sm * 3;
        }

        input + span:after{
            width:@scrubber-width-sm * 2;
            font-size:@scrubber-width-sm / 2;
        }
        input:checked + span:after {
            margin-left:@scrubber-width-sm;
        }
    }

    &.checkbox-slider-md {

        input + span {
            padding-left:@scrubber-width-md * 3;
        }

        input + span:before {
            width:@scrubber-width-md * 3;
        }

        input + span:after{
            width:@scrubber-width-md * 2;
            font-size:@scrubber-width-md / 2;
        }
        input:checked + span:after {
            margin-left:@scrubber-width-md;
        }
    }

    &.checkbox-slider-lg{

        input + span {
            padding-left:@scrubber-width-lg * 3;;
        }

        input + span:before {
            width:@scrubber-width-lg * 3;
        }

        input + span:after{
            width:@scrubber-width-lg * 2;
            font-size:@scrubber-width-lg / 2;
        }

        input:checked + span:after {
            margin-left:@scrubber-width-lg;
        }
    }
}

/*******************************************************
Slider B
*******************************************************/

.checkbox-slider--b {

    .checkbox-slider;

    input{

        + span {
            padding-left:@scrubber-width-default * 2;
        }

        + span:before {
            border-radius:@scrubber-width-default;
            width:@scrubber-width-default * 2;
        }

        + span:after{
            background:@white;
            content:"";
            width:@scrubber-width-default;
            border:solid transparent 2px;
            background-clip: padding-box;
            border-radius:@scrubber-width-default;
        }
        &:not(:checked) + span:after {
            animation: popOut ease-in .3s normal;
        }
        &:checked + span:after {
            content:"";
            margin-left:@scrubber-width-default;
            border:solid transparent 2px;
            background-clip: padding-box;
            animation: popIn ease-in .3s normal;

        }

        &:checked + span:before {
            background:@brand-success;

        }
    }

}

/*******************************************************
Slider B Sizes
*******************************************************/

.checkbox-slider--b {

    &.checkbox-slider-md {

        input + span:before {
            border-radius:@scrubber-width-md;

        }

        input + span:after{
            border-radius:@scrubber-width-md;
        }

    }

    &.checkbox-slider-lg{

        input + span:before {
            border-radius:@scrubber-width-lg;
        }

        input + span:after{
            border-radius:@scrubber-width-lg;
        }

    }
}

/*******************************************************
Slider B-flat
*******************************************************/

.checkbox-slider--b-flat {
    .checkbox-slider--b;

    input{

        + span:before {
            box-shadow:none;

        }

    }

}

/*******************************************************
Slider C
*******************************************************/

.checkbox-slider--c {

    .checkbox-slider;

    input{

        + span {
            padding-left:@scrubber-width-default * 2;
        }

        + span:before {
            height:2px !important; // needs to be
            top:@scrubber-width-default / 2;
            box-shadow:none;
            width:@scrubber-width-default * 2;
            background:@gray;
        }

        + span:after{
            box-shadow:none;
            width:@scrubber-width-default;
            border:solid @gray 2px;
            border-radius:@scrubber-width-default;
        }

        &:checked + span:after {
            background:@brand-success;
            margin-left:@scrubber-width-default;
            border:solid @brand-success 2px;
            animation: splashIn ease-in .3s normal;
        }

        &:checked + span:before {
            background:@brand-success;

        }

    }

}

/*******************************************************
Slider C Sizes
*******************************************************/

.checkbox-slider--c{

    &.checkbox-slider-sm{

        input + span:before {
            top:@scrubber-width-sm / 2 - 1;
        }
    }

    &.checkbox-slider-md{

        input + span:before {
            top:@scrubber-width-md / 2 - 1;
        }

        input + span:after{
            width:@scrubber-width-md;
            border-radius:@scrubber-width-md;
        }
    }

    &.checkbox-slider-lg{

        input + span:before {
            top:@scrubber-width-lg / 2 - 1; //correct border
        }
        input + span:after{
            width:@scrubber-width-lg;
            border-radius:@scrubber-width-lg;
        }
    }

}

/*******************************************************
Slider C-weight
*******************************************************/

.checkbox-slider--c-weight {

    .checkbox-slider--c;

    input{

        + span:before {
            height:1px !important; // needs to be
        }

        &:checked + span:before {
            height:2px !important; // needs to be
        }
        &:not(:checked) + span:after {
            transform:scale(.7);
            left:-6px
        }
    }

}

/******************************************************
State Disabled
*******************************************************/

.checkbox-slider--default input:disabled{
    + span:after{background:@gray-light;}
    + span:before{box-shadow:0 0 0 black;}
    + span {
        color:@gray-light;
    }
}
.checkbox-slider--a input:disabled{
    + span:after{background:@gray-light; color:@white;}
    + span:before{box-shadow:0 0 0 black;}
    + span {
        color:@gray-light;
    }
}
.checkbox-slider--b input:disabled{

    + span:after{
        border:solid transparent 2px;
        border-radius:@scrubber-width-lg;
    }
    + span:before{ box-shadow:0 0 0 black; }
    + span {
        color:@gray-light;
    }
}
.checkbox-slider--c input:disabled{
    &:checked + span:after {
        background:@gray-light;
    }

    + span:after{
        border-color:@gray-light;
    }
    + span:before{ background:@gray-light; }
    + span {
        color:@gray-light;
    }
}

/*******************************************************
Indicators
*******************************************************/

input:checked + .indicator-success {
    color:@brand-success;
}

input:checked + .indicator-info {
    color:@brand-info;
}

input:checked + .indicator-warning {
    color:@brand-warning;
}

input:checked + .indicator-danger {
    color:@brand-danger;
}

/*******************************************************
Sizes
*******************************************************/

// Small
.checkbox-slider-sm {
    line-height:@scrubber-width-sm;

    input + span {
        padding-left:@scrubber-width-sm * 2;
    }

    input + span:before {

        width:@scrubber-width-sm * 2;
    }

    input + span:after,
    input + span:before {
        height:@scrubber-width-sm;
        line-height:@scrubber-width-sm;
    }

    input + span:after {
        width:@scrubber-width-sm;
        vertical-align: middle;
    }

    input:checked + span:after {
        margin-left:@scrubber-width-sm;
    }
}

// Medium
.checkbox-slider-md {
    line-height:@scrubber-width-md;

    input + span {
        padding-left:@scrubber-width-md * 2;
    }

    input + span:before {

        width:@scrubber-width-md * 2;
    }

    input + span:after,
    input + span:before {
        height:@scrubber-width-md;
        line-height:@scrubber-width-md;
    }

    input + span:after {
        width:@scrubber-width-md;
        vertical-align: middle;
    }

    input:checked + span:after {
        margin-left:@scrubber-width-md;
    }
}

// Large
.checkbox-slider-lg {
    line-height:@scrubber-width-lg;

    input + span {
        padding-left:@scrubber-width-lg * 2;
    }

    input + span:before {
        width:@scrubber-width-lg * 2;
    }

    input + span:after,
    input + span:before {
        height:@scrubber-width-lg;
        line-height:@scrubber-width-lg;
    }
    input + span:after {
        width:@scrubber-width-lg;
        vertical-align: middle;
    }

    input:checked + span:after {
        margin-left:@scrubber-width-lg;
    }
}

/*******************************************************
Variations
*******************************************************/

// info
.checkbox-slider-info {
    &.checkbox-slider--default,
    &.checkbox-slider--a,
    &.checkbox-slider--c,
    &.checkbox-slider--c-weight {
        input:checked + span:after {
            background:@brand-info;
            background-clip:content-box;
        }
    }
    &.checkbox-slider--c,
    &.checkbox-slider--c-weight {
        input:checked + span:after {
                border-color:@brand-info;
            }
    }

    &.checkbox-slider--b,
    &.checkbox-slider--b-flat,
    &.checkbox-slider--c,
    &.checkbox-slider--c-weight {
        input:checked + span:before {
            background:@brand-info;
        }
    }
}
// warning
.checkbox-slider-warning {
    &.checkbox-slider--default,
    &.checkbox-slider--a,
    &.checkbox-slider--c,
    &.checkbox-slider--c-weight {
        input:checked + span:after {
            background:@brand-warning;
            background-clip:content-box;
        }
    }
    &.checkbox-slider--c,
    &.checkbox-slider--c-weight {
        input:checked + span:after {
                border-color:@brand-warning;
            }
    }

    &.checkbox-slider--b,
    &.checkbox-slider--b-flat,
    &.checkbox-slider--c,
    &.checkbox-slider--c-weight {
        input:checked + span:before {
            background:@brand-warning;
        }
    }
}
// danger
.checkbox-slider-danger {
    &.checkbox-slider--default,
    &.checkbox-slider--a,
    &.checkbox-slider--c,
    &.checkbox-slider--c-weight {
        input:checked + span:after {
            background:@brand-danger;
            background-clip:content-box;
        }
    }
    &.checkbox-slider--c,
    &.checkbox-slider--c-weight {
        input:checked + span:after {
                border-color:@brand-danger;
            }
    }

    &.checkbox-slider--b,
    &.checkbox-slider--b-flat,
    &.checkbox-slider--c,
    &.checkbox-slider--c-weight {
        input:checked + span:before {
            background:@brand-danger;
        }
    }
}
